import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import httpuserprofile from '../../js/http-lms_userprofile';
import httpcontactus from '../../js/http-lms_contactus';
import { ROUTES } from '../../Constant/Routes';
import PageSeoComponent from '../../SEO';
import SEO from '../../Constant/SEO.json'

const ContactDetails = () => {
  const [error, setError] = useState('');
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [appCode, setAppCode] = useState("");
  const [appCodeList, setAppCodeList] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [locationId, setLocationId] = useState('');
  

  const navigate = useNavigate();

  useEffect(() => {
    getUsersList();
    appcode()
  }, []);


  const appcode = () => {
    httpuserprofile
      .get('/userprofileLMS/getStaticValuesListById?indexCode=14')
      .then((result) => {
        console.log(result.data, 'AppCode');
        setAppCodeList(result.data);
      })
      .catch((err) => {
        console.log(err, 'ERRRR');
      });
  };

  const getUsersList = () => {
    httpcontactus
      .post('/allContactUS/searchContactUsWithFilters', {
          appCode: appCode || "",
          fromDate: fromDate || "",
          toDate: toDate || "",
          locationId: locationId || 0,
          limit: "",
          offset: "",
      })
      .then((response) => {
        if (response.data === 'Record not found') {
          setError(response.data);
          setRegisteredUsers([]);
        } else {
          setError('');
          setRegisteredUsers(response.data);
        }
      })
      .catch((e) => {
        console.error(e);
        setError('An error occurred while fetching data');
      });
  };

  const handleSearch = () => {
    getUsersList();
  };

  const handleClear = () => {
    setAppCode('');
    setFromDate('');
    setToDate('');
    setLocationId('');
    getUsersList();
  };

  const navToPrevRoute = () => {
    navigate(ROUTES.MY_EVENTS);
  };

  return (
    <>

<PageSeoComponent {...SEO['contact-details']} />

      <Container className="pt-5">
        <div className="my-models-title d-flex justify-content-between mb-4 mt-5">
          <div className="d-flex">
            <div
              className="d-flex align-items-center mt-4 pointer"
              onClick={navToPrevRoute}
            >
              <img
                className="pe-3 backarrow"
                src={require('../../images/leftarrow.png')}
                alt="back"
              />
              <h4 className="SectionHeadings mt-2">Event List</h4>
            </div>
          </div>
        </div>

        <Form className="mb-4">
        
            <Form.Group controlId="formAppCode">
                  <Form.Label className="required">App Code</Form.Label>
                  <Form.Select
                    name="community_type"
                    aria-label="Default select example"
                    onChange={(e) => setAppCode(e.target.value)}
                    value={appCode}
              placeholder="Select App Code"

                  >
                    <option value="">Select App Code</option>
                    {appCodeList.map((status, index) => {
                      return (
                        <option
                          value={status.indexDescription}
                          key={status.indexCode}
                        >
                          {status.indexDescription}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>




          <Form.Group controlId="formFromDate">
            <Form.Label>From Date</Form.Label>
            <Form.Control
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formToDate">
            <Form.Label>To Date</Form.Label>
            <Form.Control
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formLocationId">
            <Form.Label>Location ID</Form.Label>
            <Form.Control
              type="text"
              value={locationId}
              onChange={(e) => setLocationId(e.target.value)}
              placeholder="Enter Location ID"
            />
          </Form.Group>
          <Button variant="primary" onClick={handleSearch} className="me-2 mt-3">
            Search
          </Button>
          <Button variant="secondary" className='mt-3' onClick={handleClear}>
            Clear
          </Button>
        </Form>

        {error && <p className="text-danger">{error}</p>}

        <div className="scrollme mb-5">
          <Table className="table bgwhite">
            <thead>
              <tr>
                {/* <th scope="col">Event Name</th> */}
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Contact No.</th>
                <th scope="col">Comment</th>
                {/* <th scope="col">Event Description</th> */}
              </tr>
            </thead>
            <tbody>
              {registeredUsers.map((data, index) => (
                <tr key={index}>
                  {/* <td>{data.eventName}</td> */}
                  <td>{data.firstName}</td>
                  <td>{data.lastName}</td>
                  <td>{data.email}</td>
                  <td>{data.phoneNumber}</td>
                  <td>{data.comment}</td>
                  {/* <td>{data.eventDescription}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
    </>
  );
};

export default ContactDetails;
