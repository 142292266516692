import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Dropdown, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import httpproperties from '../../js/http-realestate-properties';
import httpcontactus from '../../js/http-lms_contactus';
import { ROUTES } from '../../Constant/Routes';
import PageSeoComponent from '../../SEO';
import SEO from '../../Constant/SEO.json';

// import axios from 'axios';


const MyEvents = () => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showActiveModal, setShowActiveModal] = useState(false);
    const [selectedModelForActive, setSelectedModelForActive] = useState(null);
    const [modelImgType, setModelImgType] = useState('');
    const [uploadeImage, setUploadedImage] = useState(null);
    const [error, setError] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredModels, setFilteredModels] = useState([]);
    const [event, setEvent] = useState([]);
    

    const navigate = useNavigate();


    function addEvent() {
        navigate(ROUTES.ADD_EVENT);
      }
    
      function navToPrevRoute() {
        navigate(ROUTES.MY_EVENTS);
      }
    
      useEffect(() => {
     getEventList()
      }, []);


      // const viewRegisteredUsers = (eventData) => {
      //   axios
      //   .post(`http://localhost:13060/eventDetails/getUsersListByEventLocation?locationId=${eventData.locationId}`)
      //   .then((response) => {
      //     if (response.data === 'Record not found') {
      //       setError(response.data);
      //     } else {
      //       console.log('fetch event list : ', response);
      //       setEvent(response.data);
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
      // };
      

    
    //   useEffect(() => {
    //     filterModels();
    //   }, [searchQuery, models]);
    
    function getEventList() {
      const requestBody = {
        cityName: null,
        stateName: null,
        countryName: null,
        eventName: null,
        eventType: null,
        venue: null,
        eventDate: null
      };

    
      httpcontactus
        .post('/eventDetails/getEventDetailsWithFilters', requestBody)
        .then((response) => {
          if (response.data === 'Record not found') {
            setError(response.data);
          } else {
            console.log('fetch event list : ', response);
            setEvent(response.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });

      

    }

    const filteredEvents = event.filter((event) =>
      event.eventName.toLowerCase().includes(searchQuery.toLowerCase())
    );
   

    
    


    const convertZuluToEST = (zuluTime) => {
      if (!zuluTime) {
        console.warn("Invalid or missing Zulu time:", zuluTime);
        return "";  // Return an empty string if zuluTime is invalid
      }
    
      console.log("Received Zulu time:", zuluTime);  // Log the input for debugging
    
      // Parse the Zulu time format (either HH.MMZ or HH:MMZ)
      const [hours, minutes] = zuluTime.includes('.') ? zuluTime.split('.') : zuluTime.split(':');
    
      // Create a UTC-based date object with parsed hours and minutes
      const utcDate = new Date(Date.UTC(1970, 0, 1, parseInt(hours), parseInt(minutes)));
    
      // Adjust to EST (UTC -5 hours)
      const estDate = new Date(utcDate.getTime() - (5 * 60 * 60 * 1000));
    
      // Format to HH:MM AM/PM in EST
      const estHours = estDate.getUTCHours() % 12 || 12; // Convert 0 to 12 for AM/PM
      const estMinutes = String(estDate.getUTCMinutes()).padStart(2, '0');
      const period = estDate.getUTCHours() < 12 ? 'AM' : 'PM';
    
      return `${estHours}:${estMinutes} ${period} EST`;
    };
    
    
    
    


    const handleUpdate = (event) => {
      setSelectedEvent(event);
      navigate(ROUTES.UPDATE_EVENT, { state: { selectedEvent: event } });
    };

    const handleRegister = (event) => {
      setSelectedEvent(event);
      navigate(ROUTES.REGISTER_EVENT, { state: { selectedEvent: event } });
    };


    

    
    
function deleteEvent(){
  if (!selectedEvent) return;
  const locationId = selectedEvent.locationId;

  // console.log(selectedEvent, "lklklklklklk")
  httpcontactus
    .delete(`/eventDetails/deleteLocationById?locationId=${locationId}`)
    .then((response) => {
      console.log('Property deleted:', response);
      getEventList()
      closeDeleteModal();
    })
    .catch((error) => {
      console.error('Error deleting property:', error);
      // Handle error here
      closeDeleteModal();
    });
}
    

      
    //   const toggleModelStatus = (modelId) => {
    //     const updatedModels = models.map((model) => {
    //       if (model.id === modelId) {
    //         return {
    //           ...model,
    //           status: model.status === 'active' ? 'inactive' : 'active',
    //         };
    //       }
    //       return model;
    //     });
    //     setModels(updatedModels);
    //   };
    
      const openDeleteModal = (event) => {
        setSelectedEvent(event)
        setShowDeleteModal(true);
      };
    
      const closeDeleteModal = () => {
       setSelectedEvent(null)
        setShowDeleteModal(false);
      };
    
      // const openActiveModal = (model) => {
      //   setSelectedModelForActive(model);
      //   setShowActiveModal(true);
      // };
    
      // const closeActiveModal = () => {
      //   setSelectedModelForActive(null);
      //   setShowActiveModal(false);
      // };
    
      // const handleChange = (event) => {
      //   const { name, value } = event.target;
      //   setModelImgType(value);
      // };
    
      
    
    //   function UpdateModel(model) {
    //     setSelectedModel(model);
    //     navigate(ROUTES.UPDATE_MODELS, { state: { selectedModel: model } });
    //   }
    
    //   const filterModels = () => {
    //     if (!searchQuery.trim()) {
    //       setFilteredModels(models);
    //     } else {
    //       const filtered = models.filter((model) =>
    //         model.modelTitle.toLowerCase().includes(searchQuery.toLowerCase())
    //       );
    //       setFilteredModels(filtered);
    //     }
    //   };
 

  return (
    <>
 <PageSeoComponent {...SEO['my-events']} />



     <Container className="pt-5">
        <div className="my-models-title d-flex justify-content-between mb-4 mt-5">
          <div className="d-flex">
            <div
              className=" d-flex align-items-center mt-4 pointer"
              onClick={navToPrevRoute}
            >
              <img
                className="pe-3 backarrow"
                src={require('../../images/leftarrow.png')}
                alt="back"
              />
              <h4 className="SectionHeadings mt-2">Event List</h4>
            </div>
          </div>
          <div className="mt-4 ">
            <Button onClick={addEvent}>Add Event</Button>
          </div>
        </div>


         <div className="container mb-5">
        <Form.Control
          type="text"
          placeholder="Search Event"
          className="w-25 mb-3"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>








        <div className="scrollme mt-5 mb-5">
        <table className="table bgwhite">
          <thead>
            <tr>
            <th scope="col">More</th>
            <th scope="col">Status</th>
              <th scope="col">Event Name</th>
              <th scope="col">Venue Date</th>
              <th scope="col">Start Time</th>
              <th scope="col">End Time</th>
              <th scope="col">Venue</th>
              <th scope="col">Location</th>
              {/* <th scope="col">Remove</th> */}
            </tr>
          </thead>
          <tbody>
          {filteredEvents.map((data, index) => (
            <tr key={index}>
                <td>
                <Dropdown drop="down-centered" align={{ lg: 'start' }}>
                  <Dropdown.Toggle
                    className="dropdown-more p-0"
                    variant="success"
                    title="more actions"
                    id="dropdown-basic"
                  >
                    <img
                      src={require('../../images/more.png')}
                      alt="More actions"
                      className="more"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        handleUpdate(data);
                        setShowActiveModal(true);
                      }}
                    >
                      Update Event
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => openDeleteModal(data)}>
                      Delete
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleRegister(data)}>
                      See Registered Users
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td>{data.status}</td>
              <td scope="row">{data.eventName}</td>
              <td>{data.eventDate}</td>
              <td>{convertZuluToEST(data.startTime)}</td>
              <td>{convertZuluToEST(data.endTime)}</td>
              <td>{data.venue}</td>
              <td>{data.cityName}, {data.stateName}, {data.countryName}</td>
            
            </tr>
          ))}
</tbody>

        </table>
      </div>

          <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the event{' '}
            {selectedEvent?.eventName}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={deleteEvent}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

       

      </Container>
    </>
  );
};

export default MyEvents;
