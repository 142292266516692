import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import crossbtn from '../images/crossbtn.svg';
import UpdatePassInside from '../Pages/UpdatePassInside';
import { ROUTES } from '../Constant/Routes';

function Sidebar(props) {
  const [pop, setPop] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Close sidebar when route changes
  useEffect(() => {
    props.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  function handleLogout() {
    // Clear session storage and navigate to the home page
    sessionStorage.clear();
    navigate(ROUTES.HOME);
  }

  function formPop(e) {
    e.preventDefault();
    setPop((pValue) => !pValue);
  }

  function handleClick(route) {
    if (location.pathname === route) {
      // Scroll to top if the route is the same
      window.scrollTo({ top: 0, behavior: 'smooth' });
      props.onClose();
    } else {
      navigate(route);
    }
  }

  return (
    <>
      {pop && <UpdatePassInside setPop={setPop} />}
      <div className={`sidebar ${props.isOpen ? 'open' : ''}`}>
        <div className="d-flex justify-content-end">
          <img
            src={crossbtn}
            alt="Close"
            className="close-btn"
            onClick={props.onClose}
            style={{ cursor: 'pointer' }}
          />
        </div>
        {sessionStorage.getItem('LOGGEDIN') ? (
          <Nav>
            <div className="header-menu">
              <ul>
                <li>
                  <Link
                    to={ROUTES.MY_PROPERTIES}
                    onClick={() => handleClick(ROUTES.MY_PROPERTIES)}
                  >
                    My Properties
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.MY_MODELS}
                    onClick={() => handleClick(ROUTES.MY_MODELS)}
                  >
                    My Models
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.MY_EVENTS}
                    onClick={() => handleClick(ROUTES.MY_EVENTS)}
                  >
                    Events
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.CONTACT_DETAILS}
                    onClick={() => handleClick(ROUTES.CONTACT_DETAILS)}
                  >
                    Contact Details
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.ADS}
                    onClick={() => handleClick(ROUTES.ADS)}
                  >
                    Facebook Ads
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.UPDATE_PROFILE}
                    onClick={() => handleClick(ROUTES.UPDATE_PROFILE)}
                  >
                    Update Profile
                  </Link>
                </li>
                <li>
                  <a onClick={formPop}>Update Password</a>
                </li>
                <li>
                  <NavDropdown.Divider />
                  <a onClick={handleLogout}>Logout</a>
                </li>
              </ul>
            </div>
          </Nav>
        ) : (
          <Nav>
            <div className="header-menu">
              <ul>
                <li>
                  <Link
                    to={ROUTES.HOME}
                    onClick={() => handleClick(ROUTES.HOME)}
                  >
                    Home
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.PROPERTY_DESC}
                    onClick={() => handleClick(ROUTES.PROPERTY_DESC)}
                  >
                    Suwanee Preserves
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.PROJECTS}
                    onClick={() => handleClick(ROUTES.PROJECTS)}
                  >
                    Find Your Home
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.SHOW_MODELS}
                    onClick={() => handleClick(ROUTES.SHOW_MODELS)}
                  >
                    Tour Models
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.SPECIFICATIONS}
                    onClick={() => handleClick(ROUTES.SPECIFICATIONS)}
                  >
                    Specifications
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.CONTACT_US}
                    onClick={() => handleClick(ROUTES.CONTACT_US)}
                  >
                    Contact Us
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.ABOUT_US}
                    onClick={() => handleClick(ROUTES.ABOUT_US)}
                  >
                    About Us
                  </Link>
                </li>

                <li>
                  <Link
                    to={ROUTES.LOGIN}
                    onClick={() => handleClick(ROUTES.LOGIN)}
                  >
                    Login
                  </Link>
                </li>
              </ul>
            </div>
          </Nav>
        )}
      </div>
    </>
  );
}

export default Sidebar;
