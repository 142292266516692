import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../Constant/Routes';

function Footer() {
  const navigate = useNavigate();




  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     const element = document.querySelector('.text-center');
  //     const cscd = element ? window.getComputedStyle(element) : '';

  //     if (
  //       element &&
  //       element.innerText.includes(
  //         'Your query is successfully submitted will get back to you soon.'
  //       ) &&
  //       (cscd.getPropertyValue('display') === 'block' ||
  //         cscd.getPropertyValue('visibility') === 'visible' ||
  //         cscd.getPropertyValue('opacity') === '1')
  //     ) {
  //       gtag('event', 'conversion', {
  //         send_to: 'AW-16741592921/1_y3CPSfz4EaENnega8-',
  //       });
  //       clearInterval(timer);
  //     }
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, []);


  // useEffect(() => {
  //   const waitForGtag = setInterval(() => {
  //     if (window.gtag) {
  //       clearInterval(waitForGtag);

  //       const timer = setInterval(() => {
  //         const element = document.querySelector('.text-center');
  //         const cscd = element ? window.getComputedStyle(element) : '';

  //         if (
  //           element &&
  //           element.innerText.includes(
  //             'Your query is successfully submitted will get back to you soon.'
  //           ) &&
  //           (cscd.getPropertyValue('display') === 'block' ||
  //             cscd.getPropertyValue('visibility') === 'visible' ||
  //             cscd.getPropertyValue('opacity') === '1')
  //         ) {
  //           window.gtag('event', 'conversion', {
  //             send_to: 'AW-16741592921/1_y3CPSfz4EaENnega8-',
  //           });
  //           clearInterval(timer);
  //         }
  //       }, 1000);

  //       return () => clearInterval(timer);
  //     }
  //   }, 100);

  //   return () => clearInterval(waitForGtag);
  // }, []);


  function handleClick(url) {
    window.open(url, '_blank'); // Open link in a new tab
  }

  function handleClickHome() {
    navigate(ROUTES.HOME);
    const lowerOffset = 0;
    window.scrollTo({ top: lowerOffset, behavior: 'smooth' });
  }

  function contactUs() {
    navigate(ROUTES.CONTACT_US);
  }

  function handleAboutUs() {
    navigate(ROUTES.ABOUT_US);
  }

  function handleFAQ() {
    window.open(require('../images/FAQ.pdf'), '_blank');
  }

  function handlePrivacyClick() {
    window.open(require('../images/KRHomesTermsandConditions.pdf'), '_blank');
  }

  function handleTermsClick() {
    window.open(require('../images/KRHomesPrivacyPolicy.pdf'), '_blank');
  }

  return (
    <>
      <section className="">
        <footer className="footcolor text-white ">
          <div className="container   pt-3 pb-2">
            <div className="row ">
              
              <div className="col-lg col-md-6 mb-4 mb-md-0">
                <img
                  className="navlink"
                  onClick={handleClickHome}
                  src={require('../images/KRHome-final-white.png')}
                  alt="First slide"
                  style={{ height: '70px' }}
                />

                {/* <div className="inter font14 mt-3">info@krhomes.net | 01114707079598</div> */}
              </div>

              <div className="col-lg col-md-6 mb-4 mb-md-0">
                {/* <h5 className="inter font18 mb-4">Our Company</h5>
                <div className="foot2">
                  <div onClick={handleClickHome}  style={{ cursor: 'pointer' }}  className="inter font16 mb-2">Home</div>
                  <div onClick={handleClick2}  style={{ cursor: 'pointer' }}  className="inter font16 mb-2">About Us</div>
                  <div   style={{ cursor: 'pointer' }} className="inter font16 mb-2">Blogs</div>
                </div> */}

                <h1 className="inter font18 ">Follow Us</h1>

                <div className="d-flex mt-1">
                  <img
                    onClick={() =>
                      handleClick('https://www.facebook.com/KRRealEst/')
                    }
                    src={require('../images/fb.png')}
                    alt="Fb Icon"
                    style={{ cursor: 'pointer' }}
                    className="me-3"
                  />

                  <img
                    onClick={() =>
                      handleClick('https://www.instagram.com/krrealest/')
                    }
                    src={require('../images/insta.png')}
                    alt="Insta Icon"
                    style={{ cursor: 'pointer' }}
                    className="me-3"
                  />

                  <img
                    onClick={() => handleClick('https://twitter.com/KRRealEst')}
                    src={require('../images/twitter.png')}
                    alt="Twitter Icon"
                    style={{ cursor: 'pointer' }}
                    className="me-3"
                  />

                  <img
                    onClick={() =>
                      handleClick('https://youtube.com/@KRRealEst')
                    }
                    src={require('../images/youtube.png')}
                    alt="Youtube Icon"
                    style={{ cursor: 'pointer' }}
                    className="me-3"
                  />

                  <img
                    onClick={() =>
                      handleClick('https://www.houzz.in/pro/krrealest')
                    }
                    src={require('../images/houzz.png')}
                    alt="houzz Icon"
                    style={{ cursor: 'pointer' }}
                    className=""
                  />
                </div>
              </div>

              <div className="col-lg col-md-6 mb-4 mb-md-0">
                {/* <h5 className="inter font18 mb-4 mt-lg-0 mt-4">Legal</h5> */}
                <div className="foot2">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={contactUs}
                    className="inter font16 mb-1"
                  >
                    Contact Us
                  </div>
                  <div className="inter font16 mb-1">
                    Email: info@krhomes.net
                  </div>
                  {/* <div  onClick={handleTermsClick}  style={{ cursor: 'pointer' }} className="inter font16 mb-2">Terms & Conditions</div> */}
                  <div
                    style={{ cursor: 'pointer' }}
                    className="d-flex"
                    onClick={() =>
                      window.open(
                        'https://api.whatsapp.com/send?phone=14707079598',
                        '_blank'
                      )
                    }
                  >
                    <img
                      className=""
                      src={require('../images/whatsappLogo.png')}
                      alt="logo"
                      style={{ height: '42px' }}
                    />
                    <div className="inter font16 my-2">+1 470-707-9598</div>
                  </div>
                </div>
              </div>

              <div className="col-lg col-md-6 mb-4 mb-md-0">
                {/* <h5 className="inter font18 mb-3">FAQ</h5> */}
                <div className="foot2">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={handleFAQ}
                    className="inter font16 mb-1"
                  >
                    FAQ
                  </div>
                  <div
                    onClick={handleTermsClick}
                    style={{ cursor: 'pointer' }}
                    className="inter font16 mb-1"
                  >
                    Privacy Policy
                  </div>
                  <div
                    onClick={handlePrivacyClick}
                    style={{ cursor: 'pointer' }}
                    className="inter font16 mb-1"
                  >
                    Terms & Conditions
                  </div>
                </div>
              </div>

              <div className="col-lg col-md-6 mb-4 mb-md-0">
                {/* <h5 className="inter font18 mb-4">Support</h5> */}
                <div className="foot2">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={handleClickHome}
                    className="inter font16 mb-1"
                  >
                    Home
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={handleAboutUs}
                    className="inter font16 mb-1"
                  >
                    About Us
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <h1 className="inter font14 text-center mt-2 mb-1">
                All content Copyright 2024, KR Real Estate Developers LLC.
                Powered by KPN Services.
              </h1>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
}

export default Footer;
