import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Constant/Routes';
import PageSeoComponent from '../../SEO';
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from 'react-datepicker';
import SEO from '../../Constant/SEO.json';
import { FaCalendarAlt } from "react-icons/fa";
import httpproperties from "../../js/http-realestate-properties";
import "react-datepicker/dist/react-datepicker.css";
import Papa from 'papaparse';

const Ads = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [delimiter, setDelimiter] = useState(','); // Default to comma
  const [file, setFile] = useState(null);
  const [pop, setPop] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [leadsCSV, setLeadsCSV] = useState([]); // Data from API
  const [selectedLead, setSelectedLead] = useState(null); // To track selected lead
  const [popupVisible, setPopupVisible] = useState(false); // Modal visibility
  const [formData, setFormData] = useState({
    // currentDate: new Date().toISOString().slice(0, 10), // Today's date
    nextFollowupDate: '',
    message: '',
    status: '', // Status from selected lead
  });

  const today = new Date();
  today.setHours(0, 0, 0, 0); 

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

const sortData = (data) => {
  if (!sortConfig.key) return data;

  return [...data].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue === null || aValue === undefined) return 1;
    if (bValue === null || bValue === undefined) return -1;

    if (typeof aValue === "string" && typeof bValue === "string") {
      return sortConfig.direction === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else {
      return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
    }
  });
};


// const today = new Date();
// today.setHours(0, 0, 0, 0); 


const handleSort = (key) => {
  setSortConfig((prev) => ({
    key,
    direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
  }));
};

const sortedLeadsCSV = sortData(leadsCSV);



  const navigate = useNavigate();

  useEffect(() => {
    getCSV(); // Fetch data on load
  }, []);

  const navToPrevRoute = () => {
    navigate(ROUTES.MY_EVENTS);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
    } else {
      alert("Please upload a valid CSV file.");
      event.target.value = ""; // Reset the input field
    }
  };
  
  const handleDelimiterChange = (event) => {
    const selectedDelimiter = event.target.value === "Tab" ? '\t' : ',';
    setDelimiter(selectedDelimiter);
  };


  const formatDate = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Add 1 because months are 0-indexed
    const day = String(d.getDate()).padStart(2, '0');
    const year = d.getFullYear(); // Get the full year
  
    return `${month}-${day}-${year}`; // Format as MM-dd-yyyy
  };
  

  const formatHeader = (field) => {
    return field
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space before uppercase letters
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };
  


  // Fetch records from API
  const getCSV = () => {
    // setLoading(true);
    httpproperties
      .get('/propertyManagement/getAllCsvRecords')
      .then((result) => {
        // Check if the response data is an array before processing
        if (Array.isArray(result.data)) {
          const filteredData = result.data.map(({ userProvidedPhoneNumber, ...rest }) => rest); // Remove userProvidedPhoneNumber
          setLeadsCSV(filteredData);
          setLoading(false);
        } else {
          setLeadsCSV([]); // Set to empty array if no records found
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  

  // Handle CSV file upload


  // const uploadFile = async () => {
  //   if (!file) {
  //     setUploadStatus('Please select a file to upload.');
  //     return;
  //   }
  
  //   try {
  //     setUploadStatus('Processing the file...');
  
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       const csvContent = e.target.result;
  
  //       // Parse the CSV content
  //       const parsedData = Papa.parse(csvContent, {
  //         header: true,
  //         skipEmptyLines: true,
  //       });
  
  //       // Convert to desired delimiter format
  //       const convertedData = Papa.unparse(parsedData.data, {
  //         delimiter: delimiter, // Use the selected delimiter
  //       });
  
  //       // Create a new Blob with the converted data
  //       const blob = new Blob([convertedData], {
  //         type: 'text/csv;charset=utf-8;',
  //       });
  
  //       // Append file to FormData
  //       const formData = new FormData();
  //       formData.append('file', blob, `converted_${file.name}`);
  
  //       // Choose API based on delimiter
  //       const API_URL = `/propertyManagement/importLeads?status=Active&createdBy=${sessionStorage.getItem(
  //         'userEmail'
  //       )}&delimiter=${delimiter === ',' ? ',' : '%09'}`;
  
      
  //       setUploadStatus('File is being uploaded...');
  //       const response = await httpproperties.post(API_URL, formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       });
  
  //       if (response.status === 200) {
  //         setUploadStatus('File uploaded successfully!');
  //         getCSV();
  //       } else {
  //         setUploadStatus(`Error: ${response.data.message || 'Failed to upload file.'}`);
  //       }
  //     };
  
  //     reader.readAsText(file);
  //   } catch (error) {
  //     setUploadStatus(`Error: ${error.response?.data?.message || error.message}`);
  //   }
  // };
  

// const uploadFile = async () => {
//   if (!file) {
//     setUploadStatus("Please select a file to upload.");
//     return;
//   }

//   try {
//     setUploadStatus("Processing the file...");

//     const reader = new FileReader();
//     reader.onload = async (e) => {
//       const csvContent = e.target.result;

//       // Parse the CSV content
//       const parsedData = Papa.parse(csvContent, {
//         header: true,
//         skipEmptyLines: true,
//       });

//       // Convert to desired delimiter format
//       const convertedData = Papa.unparse(parsedData.data, {
//         delimiter: delimiter, // Use the selected delimiter
//       });

//       // Create a new Blob with the converted data
//       const blob = new Blob([convertedData], {
//         type: "text/csv;charset=utf-8;",
//       });

//       // Append file to FormData
//       const formData = new FormData();
//       formData.append("file", blob, `converted_${file.name}`);

//       // Choose API based on delimiter
//       const API_URL = `/propertyManagement/importLeads?status=Active&createdBy=${sessionStorage.getItem(
//         "userEmail"
//       )}&delimiter=${delimiter === "," ? "," : "%09"}`;

//       setUploadStatus("File is being uploaded...");
//       const response = await httpproperties.post(API_URL, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       if (response.status === 200) {
//         const data = response.data;

//         // Handle API response
//         if (data.unknownColumnsCount === 0 && data.importStatus === "No leads are imported.") {
//           setUploadStatus("Duplicate file detected. No leads were imported.");
//         } else if (data.unknownColumnsCount > 0) {
//           const newColumns = data.unknownColumns.join(", ");
//           setUploadStatus(`New columns detected: ${newColumns}. No leads were imported.`);
//         } else {
//           setUploadStatus("File uploaded successfully!");
//         }

//         // Refresh the CSV data if needed
//         getCSV();
//       } else {
//         setUploadStatus(`Error: ${response.data.message || "Failed to upload file."}`);
//       }
//     };

//     reader.readAsText(file);
//   } catch (error) {
//     setUploadStatus(`Error: ${error.response?.data?.message || error.message}`);
//   }
// };



const uploadFile = async () => {
  if (!file) {
    setUploadStatus("Please select a file to upload.");
    return;
  }

  try {
    setUploadStatus("Processing the file...");

    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvContent = e.target.result;

      // Parse the CSV content
      const parsedData = Papa.parse(csvContent, {
        header: true,
        skipEmptyLines: true,
      });

      // Convert to desired delimiter format
      const convertedData = Papa.unparse(parsedData.data, {
        delimiter: delimiter, // Use the selected delimiter
      });

      // Create a new Blob with the converted data
      const blob = new Blob([convertedData], {
        type: "text/csv;charset=utf-8;",
      });

      // Append file to FormData
      const formData = new FormData();
      formData.append("file", blob, `converted_${file.name}`);

      // Choose API based on delimiter
      const API_URL = `/propertyManagement/importLeads?status=Active&createdBy=${sessionStorage.getItem(
        "userEmail"
      )}&delimiter=${delimiter === "," ? "," : "%09"}`;

      setUploadStatus("File is being uploaded...");
      const response = await httpproperties.post(API_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const data = response.data;
      
        // Handle API response
        if (data.unknownColumnsCount > 0) {
          const newColumns = data.unknownColumns.join(", ");
          setUploadStatus(`New columns detected: ${newColumns}. ${data.importStatus}`);
        } else {
          setUploadStatus(data.importStatus);
        }
      
        // Refresh the CSV data if needed
        getCSV();
      } else {
        setUploadStatus(`Error: ${response.data.message || "Failed to upload file."}`);
      }
      
    };

    reader.readAsText(file);
  } catch (error) {
    setUploadStatus(`Error: ${error.response?.data?.message || error.message}`);
  }
};


  // Handle Edit button click
  const handleEditClick = (lead) => {
    setSelectedLead(lead); // Save the selected lead
    setFormData({
      // currentDate: new Date().toISOString().slice(0, 10),
      id: lead.id || '',
      nextFollowupDate: lead.nextFollowupDate || '',
      message: lead.message || '',
      status: lead.status || '', // Populate current status
    });
    console.log(formData)
    setPopupVisible(true); // Show modal
  };

  // Handle input changes in the modal
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  

  // Handle record update
  const handleUpdate = () => {
    // const updatedRecord = { ...selectedLead, ...formData };

    const updatedFormData = {
      ...formData,
      // currentDate: formatDate(formData.currentDate),
      updatedBy: sessionStorage.getItem("userEmail"),
      nextFollowupDate: formatDate(formData.nextFollowupDate)
    };

    httpproperties
      .put(`/propertyManagement/updateFaceBookLeadStatus`, updatedFormData)
      .then((response) => {

        if (response.data === 'Record Updated Successfully') {

          getCSV(); // Refresh records
          setPop(true);

          setTimeout(() => {
            setPop(false);
            
          }, 1500);
        }
        else {
          console.log("ERR");
        }
        
      })
      .catch((err) => console.error(err));

    setPopupVisible(false);
  };

  // Clear modal inputs
  const handleClear = () => {
    setFormData({
      // currentDate: new Date().toISOString().slice(0, 10),
      nextFollowupDate: '',
      message: '',
      status: '',
    });
  };

  return (
    <>


{pop && (
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
          <div className=" d-flex flex-column justify-content-center align-items-center P-Update-Success-2 ">
            <div>
              <img
                src={require("../../images/Tick.png")}
                alt="success"
                className="img-fluid"
              />
            </div>
            <p className="text-center w213 ">
            Record Updated Successfully
            </p>
          </div>
        </div>
      )}


      <PageSeoComponent {...SEO['my-events']} />
      <Container className="pt-5">
        <div className="my-models-title d-flex justify-content-between mb-4 mt-5">
          <div className="d-flex">
            <div className="d-flex align-items-center mt-4 pointer" onClick={navToPrevRoute}>
              <img className="pe-3 backarrow" src={require('../../images/leftarrow.png')} alt="back" />
              <h4 className="SectionHeadings mt-2">Ads</h4>
            </div>
          </div>
        </div>

        {/* Search and File Upload */}



        <div className="container mb-5">
  <h3>Upload CSV File</h3>

  {/* <Form.Group controlId="delimiterSelect" className="mb-3">
    <Form.Label>Select Delimiter</Form.Label>
    <Form.Control as="select" onChange={handleDelimiterChange}>
      <option value=",">Comma</option>
      <option value="Tab">Tab</option>
    </Form.Control>
  </Form.Group> */}



  <Form.Group controlId="delimiterSelect" className="mb-3">

                  <Form.Label>Select Delimiter</Form.Label>
                  <Form.Select
                    onChange={handleDelimiterChange}
                  >
                    {/* <option value="">Select Status</option> */}
                    
                    <option value=",">Comma</option>
      <option value="Tab">Tab</option>
                      
                  </Form.Select>
                </Form.Group>




  

  <Form.Group controlId="formFile" className="mb-3">
    <Form.Label>Choose CSV File</Form.Label>
    <Form.Control type="file" accept=".csv" onChange={handleFileChange} />
  </Form.Group>

 

  <Button onClick={uploadFile} className="btn btn-primary">
    Upload File
  </Button>

  {uploadStatus && <p className="mt-3">{uploadStatus}</p>}
</div>


        {/* Table */}
        <div className="scrollme-container mt-5 mb-5">
  <div className="scrollme">
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Edit</th>
          {leadsCSV.length > 0 &&
            Object.keys(leadsCSV[0]).map((field, index) => (
              <th
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => handleSort(field)}
              >
                {formatHeader(field)}{" "}
                {sortConfig.key === field && (sortConfig.direction === "asc" ? "↑" : "↓")}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={Object.keys(leadsCSV[0] || {}).length + 1} style={{ textAlign: "center" }}>
              <Spinner
                animation="border"
                className="text-center forgetpass-spinner mb-2"
                variant="warning"
              />
            </td>
          </tr>
        ) : sortedLeadsCSV.length > 0 ? (
          sortedLeadsCSV.map((lead, index) => (
            <tr key={index}>
              <td>
                <Button variant="primary" onClick={() => handleEditClick(lead)}>
                  Edit
                </Button>
              </td>
              {Object.keys(lead).map((field, fieldIndex) => (
                <td key={fieldIndex}>
                  {lead[field]
                    ? field === "updatedAt"
                      ? lead[field].split("T")[0]
                      : lead[field]
                    : "-"}
                </td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={Object.keys(leadsCSV[0] || {}).length + 1} style={{ textAlign: "center" }}>
              No records found.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
</div>







        {/* Edit Modal */}
        <Modal
  className="w-100 px-5"
  show={popupVisible}
  onHide={() => setPopupVisible(false)}
  centered // Add this property to center the modal vertically
>
  <Modal.Header closeButton>
    <Modal.Title>Edit Record</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      {/* <Form.Group>
        <Form.Label>Updation Date</Form.Label>
        <Form.Control type="date" value={formData.currentDate} disabled />
      </Form.Group> */}


{/* <Form.Group>
  <Form.Label>Next Followup Date</Form.Label>
  <Form.Control
    type="date"
    name="nextFollowupDate"
    min={new Date().toLocaleDateString('en-CA')} // Adjust for local timezone
    value={formData.nextFollowupDate}
    onChange={handleInputChange}
  />
</Form.Group> */}




<Form.Group>
  <div className="d-flex flex-column">
    <Form.Label>Next Followup Date</Form.Label>
    <DatePicker
      selected={formData.nextFollowupDate ? new Date(formData.nextFollowupDate) : null} // Convert string to Date
      onChange={(date) =>
        handleInputChange({
          target: { name: "nextFollowupDate", value: date.toISOString() }, // Store in ISO format
        })
      }
      minDate={today}
      dateFormat="MM-dd-yyyy" // Display format
      className="form-control"
      customInput={
        formData.nextFollowupDate ? (
          // Show the already selected date as plain text
          <div className="d-flex align-items-center">
            <span className="form-control bg-light">{new Date(formData.nextFollowupDate).toLocaleDateString('en-US')}</span>
            <FaCalendarAlt className="date-picker-icon me-3" />
          </div>
        ) : (
          // Show the custom input for selecting a date
          <div className="custom-date-picker">
            <input
              type="text"
              className="form-control"
              placeholder="Select a date"
              readOnly
            />
            <FaCalendarAlt className="date-picker-icon me-3" />
          </div>
        )
      
      }
    />
  </div>
</Form.Group>



{/* <Form.Group>
      <div className="d-flex flex-column">
        <Form.Label>Next Followup Date</Form.Label>
        <div className="input-group">
          <DatePicker 
            selected={formData.nextFollowupDate ? new Date(formData.nextFollowupDate) : null} // Convert string to Date
            onChange={(date) =>
              handleInputChange({
                target: { name: "nextFollowupDate", value: date.toISOString() }, // Store in ISO format
              })
            }
            minDate={new Date()} // Prevent selection of past dates
            dateFormat="MM-dd-yyyy" // Display format
            className="form-control" // Bootstrap style
            customInput={
              <div className="custom-date-picker">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select a date"
                  readOnly
                />
                <FaCalendarAlt className="date-picker-icon me-2" />
              </div>
            }
          />
        </div>
      </div>
    </Form.Group> */}


      <Form.Group>
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          placeholder="Enter your message"
        />
      </Form.Group>


      <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    onChange={handleInputChange}
                    name="status"
                    value={formData.status}
                  >
                    {/* <option value="">Select Status</option> */}
                    
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                      
                  </Form.Select>
                </Form.Group>



      {/* <Form.Group>
        <Form.Label>Status</Form.Label>
        <Form.Control
          as="select"
          name="status"
          value={formData.status}
          onChange={handleInputChange}
        >
          <option value="Active">Active</option>
          <option value="InActive">InActive</option>
        </Form.Control>
      </Form.Group> */}



    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="success" onClick={handleUpdate}>
      Update
    </Button>
    <Button variant="secondary" onClick={handleClear}>
      Clear
    </Button>
    <Button variant="danger" onClick={() => setPopupVisible(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

      </Container>
    </>
  );
};

export default Ads;
